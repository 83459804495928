import { type ICommandResult } from '~/api-types';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import { useErrorStore } from '~/store/error';

export default function useLogin() {
  const runtimeConfig = useRuntimeConfig();
  const uiStore = useUiStore();
  const globalContentStore = useGlobalContentStore();
  const errorStore = useErrorStore();

  const logout = async(preventNavigate: boolean) => {
    const result: ICommandResult<{}> = await $fetch(`${runtimeConfig.public.apiUrl}user/session`, {
      method: 'DELETE',
      body: JSON.stringify({}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': globalContentStore.getAcceptLanguage,
        'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
      },
    });
    if (result.success) {
      uiStore.setShowMobileMenu(false);
      uiStore.setIsLoggedIn(false);
      if (!preventNavigate) {
        window.location.href = '/';
      }
      uiStore.checkLoggedIn();
      globalContentStore.loadFromCms();
      sessionStorage.removeItem('pushed-logged-in');
    } else {
      errorStore.displayError({ message: result.errorMessage || 'Couldn\'t logout. Please try again' });
      window.dataLayer?.push({
        event: 'Error',
        errorMessage: result.errorMessage || 'Reset password error',
      });
    }
  };
  
  return {
    logout,
  };
}
